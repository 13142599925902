@keyframes highlight {
  from {
    background-color: #FFFDAF;
  }
}

.alert {
  left: 10%;
  margin: 0 auto;
  position: absolute;
  bottom: 5rem;
  width: 80%;
  z-index: 10000;
}

.highlight {
  animation: highlight 1000ms;
}

.leaflet-container {
  height: 100vh;
  position: relative;
  width: 100%;

  #block-interaction > div {
    height: 9999px;
    position: absolute;
    width: 9999px;
    z-index: 999;
  }

  .leaflet-pane {
    z-index: inherit;

    &.leaflet-map-osm-greyscale-pane {
      filter: grayscale(100%);
    }

    /*
      Respect the following layer order:
        0. leaflet-control-container           1000   The controls
        1. leaflet-block-interaction-pane       999   Cover everything when tools are being used
        2. leaflet-tooltip-pane                 700   The tooltip (on hovered elements)
        3. leaflet-popup-pane                   650   The popup (on selected elements)
        4. leaflet-selected-coordinates-pane    550   The pin (on right-click)
        5. leaflet-selected-plots-pane          500   The selected plots (on selected elements)
        6. leaflet-marker-pane                  450   The custom markers (like the substation sun)
        7. leaflet-overlay-pane                 400   The general and search data
        8. leaflet-tile-pane                    200   The map tiles
        9. leaflet-substation-zones-pane        100   The dashed lines marking substation zones
     */

    &.leaflet-tooltip-pane {
      z-index: 700;
    }

    &.leaflet-popup-pane {
      z-index: 650;
    }

    &.leaflet-selected-coordinates-pane {
      z-index: 550;
    }

    &.leaflet-selected-plots-pane {
      z-index: 500;
    }

    &.leaflet-marker-pane {
      z-index: 450;
    }

    &.leaflet-overlay-pane {
      z-index: 400;
    }

    &.leaflet-tile-pane {
      z-index: 200;
    }

    &.leaflet-substation-zones-pane {
       z-index: 100;
    }
  }

  .leaflet-tooltip {
    white-space: break-spaces;
  }

  .leaflet-marker-icon-custom {
    .btn {
      border: 0;
      padding: 0 !important;
    }
  }

  .leaflet-marker-pane .plot-label {
    color: #FFF;
    font-size: 14px;
    margin-left: -20px !important;
    margin-top: -10px !important;
    text-shadow: 1px 1px 1px #385050;
    white-space: nowrap;
  }
}

.filter {
  background: #FFF;
  border: 1px solid #DEE2E6;
  cursor: default;

  &.striped {
    li:nth-of-type(even) {
      background: #f4f4f4;
    }
  }

  .list-group-item {
    background: transparent;
  }
}

.leaflet-popup-content {
  margin: 10px;
}

.stripes {
  background-image: linear-gradient(45deg, #FFF 33.33%, #F0F0F0 33.33%, #F0F0F0 50%, #FFF 50%, #FFF 83.33%, #F0F0F0 83.33%, #F0F0F0 100%);
  background-size: 30px 30px;
}

/* json-edit-react */
.jer-editor-container {
  background-color: transparent !important;
  font-family: sans-serif !important;
  font-size: inherit !important;
  padding: 0 !important;

  .jer-component {
    margin-left: .6em !important;

    .jer-key-color-green > .jer-component > .jer-collection-header-row .jer-key-text {
      color: green !important;
    }
    .jer-key-color-red > .jer-component > .jer-collection-header-row .jer-key-text {
      color: red !important;
    }

    &.jer-value-component {
      margin-left: 0 !important;

      .jer-value-color-green {
        color: green;
      }
      .jer-value-color-red {
        color: red;
      }
    }

    .jer-collection-header-row,
    .jer-value-main-row {
      min-height: unset;
    }

    .jer-collapse-icon {
      top: 0;
    }
  }

  .jer-clickzone,
  .jer-collection-header-row:has(.jer-key-text span:empty), /* remove opening { */
  > .jer-component > .jer-collection-inner > .jer-bracket-outside { /* remove closing } */
    display: none;
  }
}

/* react-slider */
.slider {
  height: 18px;
  width: 100%;

  &.disabled .thumb {
    background: #212529bf;
  }

  .thumb {
    background: #0d6efd;
    border-radius: 1rem;
    cursor: pointer;
    height: 1rem;
    top: 1px;
    width: 1rem;
  }

  .track {
    background: var(--bs-secondary-bg);
    border-radius: 1rem;
    height: .5rem;
    top: 5px;

    &.track-1 {
      background: rgba(33, 37, 41, .25);
    }
  }
}

/* Action buttons */
.leaflet-bottom.leaflet-right {
  .leaflet-control {
    > a { /* PolylineMeasure controls don't come with a pointer by default */
      cursor: pointer;

      &.polyline-measure-controlOnBgColor {
        background-color: #FFFDAF;
        box-shadow: none;
      }
    }

    .active a.leaflet-buttons-control-button {
      background-color: #FFFDAF;
      box-shadow: none;
    }
  }

  .leaflet-control-attribution {
    margin-top: 10px;
  }

  .leaflet-bar a:last-child,
  .leaflet-pm-toolbar .button-container:last-child a.leaflet-buttons-control-button {
    border-bottom: 1px solid #ccc !important;
  }

  .easy-button-container,
  .leaflet-bar.leaflet-control:not(.leaflet-control-zoom) {
    border-bottom: 0;
    border-top: 0;
    margin-bottom: 0 !important;
  }

  .easy-button-container {
    position: relative;

    .easy-button-button {
      background-color: #fff;
      background-position: 50% 50%;
      border: 0;
      border-bottom: 1px solid #ccc;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      height: 30px;
      line-height: 30px;
      width: 30px;

      &:hover {
        background-color: #F4F4F4;
      }

      &.button-active-active {
        background-color: #FFFDAF;
      }
    }

    .leaflet-pm-actions-container {
      direction: ltr;
      position: absolute;
      right: 100%;
      top: -86px;
      white-space: nowrap;
      z-index: 2;

      &#circle-buffer-action {
        top: 0;
      }

      a {
        background-color: #666;
        color: #FFF;
        cursor: pointer;
        display: inline-block;
        height: 29px;
        line-height: 29px;
        padding: 0 10px;
        width: auto;

        &:hover {
          color: #fff;
        }
      }

      input {
        height: 29px;
        width: 40px;
      }
    }
  }
}

/* Accordion button for field filters */
form.field-filters .accordion-button {
  font-size: revert;
  padding: .25rem;
}

/* Custom control buttons */
.heightgraph.leaflet-control {
  bottom: 15px;
  position: absolute;
}

.leaflet-top.leaflet-right .easy-button-container,
.leaflet-top.leaflet-left .easy-button-container {
  border: 2px solid rgba(0, 0, 0, 0.2) !important;

  .easy-button-button {
    border: 0;
    border-radius: 5px;
    height: 44px;
    width: 44px;

    svg {
      fill: gray;
      height: 25px;
      width: 25px;
    }
  }
}

#panel {
  cursor: default;
  max-width: 500px !important;
  min-width: 300px !important;

  .content {
    padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
    padding-top: 0;

    ul {
      padding-left: 0;

      li {
        margin-left: 1rem;

        &[role="button"]:hover,
        [role="button"]:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .form-check {
    margin-bottom: unset;
  }

  label.form-check-label {
    line-height: 1;
  }

  .nav-item {
    svg {
      cursor: pointer;
      fill: rgba(var(--bs-secondary-rgb), .6);

      &:hover {
        fill: rgba(var(--bs-secondary-rgb), .8);
      }
    }

    &.active svg {
      fill: var(--bs-secondary-rgb);
    }
  }
}
